<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="999"
    class="table-dctf-detail"
    dense
    hide-default-footer
  >
    <template v-slot:item.name="{ item }"> Dcomp - {{ item.name }} </template>
    <template v-slot:item.creditValue="{ item }">
      <strong>{{ item.creditValue | money | emptyCurrency }}</strong>
    </template>
  </v-data-table>
</template>

<script>
import ECacService from '@/services/ecacService.js';

export default {
  name: 'Dcomp',

  props: ['dctfId', 'id'],

  data() {
    return {
      dialog: false,
      loading: false,
      headers: [
        { text: 'Detalhamento', value: 'name', align: 'start', sortable: false },
        { text: 'Valor do crédito', value: 'creditValue', align: 'end', sortable: false },
      ],

      items: [],
    };
  },

  created() {
    if (this.dctfId && this.id) this.fetch(this.dctfId, this.id);
  },

  methods: {
    async fetch(dctfId, id) {
      this.loading = true;
      try {
        let response = await ECacService.findDCOMP(dctfId, id);
        this.items = response.data.content.map(item => ({
          name: item[1],
          creditValue: parseFloat(item[0],).toLocaleString('pt-BR',{ style: 'currency', currency: 'BRL' })
        }));
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.table-dctf-detail >>> th {
  padding: 0 6px !important;
}
.table-dctf-detail >>> td {
  font-size: x-small !important;
  font-weight: 500;
}
</style>
