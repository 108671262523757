var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "table-dctf-detail",
    attrs: {
      headers: _vm.headers,
      items: _vm.items,
      "items-per-page": 999,
      dense: "",
      "hide-default-footer": "",
    },
    scopedSlots: _vm._u([
      {
        key: "item.name",
        fn: function ({ item }) {
          return [_vm._v(" Dcomp - " + _vm._s(item.name) + " ")]
        },
      },
      {
        key: "item.creditValue",
        fn: function ({ item }) {
          return [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm._f("emptyCurrency")(_vm._f("money")(item.creditValue))
                )
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }